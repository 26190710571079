import loadable, { lazy } from '@loadable/component';
import { timeout } from 'promise-timeout';

// components
import { Loader } from '../components';

// config
import { PAGE_LOAD_TIMEOUT } from '../config/global';

// import your loader component
const PageLoader = () => <Loader />;

//* Export all pages

// auth settings pages
export const LoginPage = loadable(
  () => timeout(import('./Authorization/Login'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ResetPaswwordPage = loadable(
  () => timeout(import('./Authorization/ResetPassword'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ForgotPasswordPage = loadable(
  () => timeout(import('./Authorization/ForgotPassword'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ConfirmResetPassword = loadable(
  () => timeout(import('./Authorization/ConfirmResetPassword'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const RegisterPage = loadable(
  () => timeout(import('./Authorization/Register'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ApplicationFormPage = loadable(
  () => timeout(import('./Authorization/ApplicationForm'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

// root settings pages
export const DashboardPage = loadable(
  () => timeout(import('./Dashboard/Stats/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const PriceLogsPage = loadable(
  () => timeout(import('./Dashboard/PriceLogsPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const PriceProfilesPage = loadable(
  () => timeout(import('./Dashboard/PriceProfilesPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const CampaignsPage = loadable(
  () => timeout(import('./Dashboard/Campaigns/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const MetricsUserPage = loadable(
  () => timeout(import('./Dashboard/MetricsUser'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ProfitPage = loadable(
  () => timeout(import('./Dashboard/ProfitPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const CompanyPage = loadable(
  () => timeout(import('./Dashboard/Company/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ExportPage = loadable(
  () => timeout(import('./Dashboard/Export/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const LocalSellerImport = loadable(
  () => timeout(import('./Dashboard/LocalSellerImport/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const AdvancedPage = loadable(
  () => timeout(import('./Dashboard/AdvancedSettings/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ImportPage = loadable(
  () => timeout(import('./Dashboard/Import/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ExchangePage = loadable(
  () => timeout(import('./Dashboard/Exchange/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ProductsPage = lazy(() => import('./Dashboard/Products/index'));
export const ProductsReviewPage = lazy(() => import('./Dashboard/ProductsReview/index'));

export const AttributesPage = loadable(
  () => timeout(import('./Dashboard/Attributes/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const CategoriesPage = loadable(
  () => timeout(import('./Dashboard/Categories/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const AtributeGroupPage = loadable(
  () => timeout(import('./Dashboard/AttributeGroups/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const FilterAttributePage = loadable(
  () => timeout(import('./Dashboard/FilterAttributes/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const PermissonsPage = loadable(
  () => timeout(import('./Dashboard/Permissions/PermissonsPage'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const LanguagesPage = loadable(
  () => timeout(import('./Dashboard/Languages/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const SellerInformationsPage = loadable(
  () => timeout(import('./Dashboard/SellerInformations/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const PricingPage = loadable(
  () => timeout(import('./Dashboard/Pricing/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const LocalSellersPage = loadable(
  () => timeout(import('./Dashboard/LocalSellersPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const LocalProductPage = loadable(
  () => timeout(import('./Dashboard/LocalProductPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const LocalProductArchivePage = loadable(
  () => timeout(import('./Dashboard/LocalProductArchivePage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const LocalServicePage = loadable(
  () => timeout(import('./Dashboard/LocalServicePage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const LocalAcademyPage = loadable(
  () => timeout(import('./Dashboard/LocalAcademyPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const MergedOrdersPage = loadable(
  () => timeout(import('./Dashboard/MergedOrdersPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const SplitLocalOrderPage = loadable(
  () => timeout(import('./Dashboard/SplitLocalOrderPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const SplitResellerOrderPage = loadable(
  () => timeout(import('./Dashboard/SplitResellerOrderPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const DeliveryTimePage = loadable(
  () => timeout(import('./Dashboard/DeliveryTime/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const SendToShopPage = loadable(
  () => timeout(import('./Dashboard/SendToShop/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const ServicePage = loadable(
  () => timeout(import('./Dashboard/Service/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const SmtpEmailPage = loadable(
  () => timeout(import('./Dashboard/SmtpEmail/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const PackagesPage = loadable(
  () => timeout(import('./Dashboard/Packages/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const BulkOrderPage = loadable(
  () => timeout(import('./Dashboard/BulkOrders/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const POItemsListPage = loadable(
  () => timeout(import('./Dashboard/POItemsListPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const POLocalItemsListPage = loadable(
  () => timeout(import('./Dashboard/POLocalItemsListPage/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const BulkOrdersLineItemsPage = loadable(
  () => timeout(import('./Dashboard/BulkOrdersLineItems/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

export const CloneProductsPage = loadable(
  () => timeout(import('./Dashboard/CloneProducts/index'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);

// error pages
export const ErrorPage = loadable(
  () => timeout(import('./ErrorPage/NotFound'), PAGE_LOAD_TIMEOUT),
  {
    fallback: <PageLoader />,
  },
);
