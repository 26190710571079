import { useEffect, useState, useRef } from 'react';
import { Col, Collapse, Form, Input, Row, Tree, TreeProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Api from '../../../../api/index';
import { toastNotification } from '../../../../utils/toastNotification';
import { ShopCategoriesTree } from '../Product.types';

export const ShopProductCategories = ({
  loading,
  visible,
  categories,
  product_id,
  handleSelect,
}: ShopCategoriesTree) => {
  const [selected, setSelected] = useState<any>([]);
  const [activeKey, setActiveKey] = useState(['0']);
  const [filteredData, setFilteredData] = useState(categories);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [manualExpandedKeys, setManualExpandedKeys] = useState<string[]>([]);
  const searchTimeoutRef = useRef<any>(null);

  const onCheckTreeOptions: TreeProps['onCheck'] = (checkedKeys: any, info: any) => {
    if (info.checked && info.node.children) {
      checkedKeys.checked.pop();
      toastNotification('warn', 'Cannot check this parent node!');
      return;
    }
    handleSelect(checkedKeys?.checked);
    setSelected(checkedKeys?.checked);
  };

  const getCategoriesByProduct = () => {
    Api(`mapped-category/categoriesByProduct/${product_id}`, 'POST', null)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          setSelected(result);
          handleSelect(result);
          setExpandedKeys(result);
        }
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const handleCollapse = (key: string | string[]) => {
    const newKey = Array.isArray(key) ? key : [key];
    setActiveKey(newKey as string[]);
  };

  // Handle manual node expansion (by user click)
  const onExpand = (expandedKeys: any) => {
    setManualExpandedKeys(expandedKeys); // Track manually expanded nodes
  };

  // Recursive function to filter the tree data and collect expanded keys
  const filterTreeData = (data: any[], keyword: string, parentKeys: string[] = []): any[] => {
    return data
      .map(node => {
        const currentParentKeys = [...parentKeys, node.originalId]; // Store current node's key in parent chain

        if (node.title.toLowerCase().includes(keyword.toLowerCase())) {
          // If node matches, add all its parents to expandedKeys
          setExpandedKeys(prevKeys => [...new Set([...prevKeys, ...currentParentKeys])]);
          return node;
        }

        if (node.children) {
          const filteredChildren = filterTreeData(node.children, keyword, currentParentKeys);
          if (filteredChildren.length > 0) {
            return { ...node, children: filteredChildren };
          }
        }
        return null;
      })
      .filter(node => node !== null);
  };

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      if (keyword === '') {
        setFilteredData(categories);
        setExpandedKeys(selected);
      } else {
        setExpandedKeys([]);
        const filtered = filterTreeData(categories, keyword);
        setFilteredData(filtered);
      }
    }, 300);
  };

  useEffect(() => {
    getCategoriesByProduct();
    return () => {
      setSelected([]);
    };
  }, []);

  useEffect(() => {
    setActiveKey(selected.length > 0 ? ['1'] : ['0']);
  }, [selected]);

  return (
    <Row gutter={16}>
      <Col span={24} className="category__wrapper">
        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} className="category-tree">
          <Col span={24} className="category-col">
            <Collapse
              activeKey={activeKey}
              onChange={handleCollapse}
              expandIconPosition="end"
              expandIcon={({ isActive }) => <DownOutlined size={18} rotate={isActive ? 0 : 180} />}
            >
              <p>
                <span className="color-red">*</span>Zgjedhni kategoritë
              </p>
              <Collapse.Panel header="Kategoritë" key="1">
                <Input
                  placeholder="Search categories..."
                  value={searchKeyword}
                  onChange={e => handleSearch(e.target.value)}
                  style={{ marginBottom: 16 }}
                />
                <Tree.DirectoryTree
                  key="id"
                  checkable
                  checkStrictly
                  showIcon={false}
                  defaultCheckedKeys={selected}
                  defaultExpandedKeys={[...new Set([...selected, ...manualExpandedKeys])]}
                  fieldNames={{ key: 'originalId' }}
                  height={window.screen.height * 0.9}
                  switcherIcon={<DownOutlined />}
                  onCheck={onCheckTreeOptions}
                  autoExpandParent={true}
                  expandedKeys={[...new Set([...expandedKeys, ...manualExpandedKeys])]}
                  onExpand={onExpand}
                  treeData={filteredData}
                />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Form.Item>
      </Col>
    </Row>
  );
};
