import { ReactElement } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Sidebar, Header } from '../shared';

export const AppLayout = ({ children }: { children: ReactElement }) => {
  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ overflow: 'initial' }}>{children}</Content>
      </Layout>
    </Layout>
  );
};
