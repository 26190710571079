/* eslint-disable camelcase */
import { Button, Checkbox, Form, Input, Popconfirm, Select, Space, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../api';
import { Images } from '../../../assets/images/Index';
import { PermissionEnum } from '../../../enums/Permission.enum';
import { ProductShopStatusEnum, ProductStatusEnum } from '../../../enums/ProductStatus.enum';
import { ProductFilter } from '../../../interfaces';
import '../../../sass/components/_filters.scss';
import { useAuthStore } from '../../../store';
import { buildSelectTree } from '../../../utils/buildTree';
import { changeProductStatus } from '../../../utils/checkProductStatus';
import { toastNotification } from '../../../utils/toastNotification';
import { ProductFilterProps } from '../Products/Product.types';
import { CategorySelectTree } from './CategorySelectTree';
import { ManageCategorySelectTree } from './ManageCategorySelectTree';

export const CategoriesFilters = ({
  selectedRows,
  setSelectedRows,
  searchValue,
  users,
  shopCategories,
  setSearchValue,
  setCategorySubmit,
  sendProductsToShop,
  changeProductsStatus,
  categorySubmit,
  searchParsedSeller,
  loading,
  shopId,
}: ProductFilterProps) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { user } = useAuthStore();
  const { t } = useTranslation();

  const [saveCategoryId, setSaveCategoryId] = useState([]);
  const [functionality, setFunctionality] = useState([]);
  const [sellerCategories, setSellerCategories] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [intCategory, setIntCategory] = useState(false);
  const [activeFilters, setActiveFilters] = useState(false);
  const [searchType, setSearchType] = useState<string>('sku');

  const [exportType, setExportType] = useState<{
    type: Record<'main' | 'configurator' | 'image', boolean>;
    email: string;
    sendRaport: boolean;
  }>({
    type: {
      main: true,
      image: false,
      configurator: false,
    },
    email: '',
    sendRaport: false,
  });

  const [productStatus, setProductStatus] = useState<number | null>(null);

  const checkAccess = (permission: string) => user?.permissions?.includes(permission);
  const searchParsed = useMemo(() => queryString.parse(search), [search]);

  const handleChangeProductStatus = () => {
    if (productStatus !== null && changeProductsStatus) {
      changeProductsStatus(productStatus);
    }
  };

  const defaultExportType = {
    type: {
      main: true,
      image: false,
      configurator: false,
    },
    email: '',
    sendRaport: false,
  };

  const handleSendProducsInShop = () => {
    if (sendProductsToShop) {
      sendProductsToShop(
        selectedRows,
        exportType.type,
        exportType.sendRaport ? exportType?.email : '',
        new Date(),
      );
      setExportType(defaultExportType);
    }
  };

  const handleApproveSendInShop = () => {
    if (sendProductsToShop) {
      sendProductsToShop(
        selectedRows,
        { main: true, image: true },
        exportType.sendRaport ? exportType?.email : '',
        new Date(),
      );
      setExportType(defaultExportType);
    }
  };

  const handleGetAllSellers = () => {
    api('seller/getSellers', 'POST', { local_seller: false }, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          setSellers(
            result.filter(function (person: any) {
              return person.name !== 'Shopware';
            }),
          );
        }
      })
      .catch(({ statusMeesage }) => toastNotification('error', statusMeesage));
  };

  const statusSelect = useMemo(
    () => changeProductStatus(Number(searchParsed?.status || 1)),
    [searchParsed],
  );

  const handleFunctionalityChange = (value: any) => {
    setFunctionality(value);
  };

  const handleOnFinish = () => {
    if (saveCategoryId.length == 0) {
      toastNotification('error', 'Select category');
      return;
    }

    if (selectedRows.length == 0) {
      toastNotification('error', 'Select a product');
      return;
    }
    api(
      `product/map/bulk/categories`,
      'PUT',
      {
        data: { selectedRows, id: saveCategoryId, functionality },
      },
      { shopId },
    )
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) toastNotification('success', 'success');
        setCategorySubmit(!categorySubmit);
        form.setFieldsValue({ category_id: null });
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const handleSetParams = (value: { [key: string]: string | boolean | number | string[] }) => {
    navigate(
      {
        search: queryString.stringify(
          { ...searchParsed, ...value, page: '0', pageSize: '10' },
          { skipEmptyString: true },
        ),
      },
      { replace: true },
    );
  };

  const handleSetFilter = (value: ProductFilter) => {
    setSearchValue({ ...searchValue, ...value });
    handleSetParams({ ...value });
  };
  const handleSelectCategory = (category: any) => handleSetFilter({ category });
  const handleSearch = (value: string) => {
    const search_type = searchType || 'sku';
    handleSetParams({ search_text: value, search_type });
  };

  const handleSelectStatus = (status: string) => handleSetFilter({ by_status: status });

  const handleSelectSeller = (seller: string) => {
    handleSetFilter({ seller });
    getSelectSeller(seller);
    seller == '' ? setIntCategory(false) : setIntCategory(true);
  };

  const handleSelectSearchType = (search_type: string) => {
    setSearchType(search_type);
  };

  const selectAfter = (
    <Select
      defaultValue={searchType || 'SKU'}
      onChange={handleSelectSearchType}
      style={{ width: 'fit-content', paddingLeft: '5px' }}
    >
      <Select.Option value="sku">SKU</Select.Option>
      <Select.Option value="ean">EAN</Select.Option>
      <Select.Option value="brand">BRAND</Select.Option>
      <Select.Option value="name">NAME</Select.Option>
      <Select.Option value="all">ALL</Select.Option>
    </Select>
  );

  // const handleSelectUser = (user: string) => {
  //   setSearchValue({ ...searchValue, user });
  //   handleSetParams({ user });
  // };

  const clearFilter = () => {
    form.resetFields();
    setIntCategory(false);
    setSellerCategories([]);
    navigate(
      {
        search: queryString.stringify({
          shopId: searchParsed?.shopId,
          status: searchParsed?.status,
        }),
      },
      { replace: true },
    );
  };

  const getSelectSeller = (seller_id: string) => {
    api(`seller/${seller_id}`, 'GET', null, { shopId })
      .then(async ({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          const categories = buildSelectTree(result?.categories || []);
          setSellerCategories(categories || []);
        }
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  useEffect(() => {
    const hasActiveFilters = Object.keys(searchParsed)
      .filter(key => key !== 'status' && key !== 'page' && key !== 'pageSize' && key !== 'shopId')
      .some(key => searchParsed[key] !== undefined && searchParsed[key] !== '');
    setActiveFilters(hasActiveFilters);
  }, [searchParsed]);

  useEffect(() => {
    handleGetAllSellers();
  }, []);

  useEffect(() => {
    setProductStatus(null);
  }, [searchParsed?.status]);

  return (
    <div className="all-product-filter">
      <Form form={form} layout="inline">
        <div className="first__filter">
          <div>
            <h2>Manage Product Categories</h2>
          </div>
          {checkAccess(PermissionEnum.UPDATE_PRODUCT) && (
            <div className="category__map">
              <Select
                defaultValue="Action"
                style={{ width: 120 }}
                onChange={handleFunctionalityChange}
              >
                <Select.Option value="Add">Add</Select.Option>
                <Select.Option value="Remove">Remove</Select.Option>
              </Select>
              <Form.Item name="category_id" label="" className="select-width category__select">
                <ManageCategorySelectTree
                  shopCategories={shopCategories}
                  onChange={handleSelectCategory}
                  category={false}
                  setSaveCategoryId={setSaveCategoryId}
                  placeholder="All"
                />
              </Form.Item>
              <Tooltip
                title={
                  saveCategoryId.length == 0 || saveCategoryId == null
                    ? 'Select Category'
                    : selectedRows.length == 0
                    ? 'Select a product'
                    : functionality.length == 0
                    ? 'Select an action'
                    : ''
                }
                placement="top"
              >
                <Button
                  disabled={
                    saveCategoryId.length == 0 ||
                    selectedRows.length == 0 ||
                    functionality.length == 0
                  }
                  className="map"
                  onClick={handleOnFinish}
                >
                  Apply
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
        <Space
          align="start"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div className="second__filter">
            <Form.Item name="search_text" className="form-search-filters input__search">
              <Input.Search
                addonBefore={selectAfter}
                className="input_color"
                type="text"
                placeholder="Sku, etc."
                allowClear
                defaultValue={searchParsed.search_text?.toString() || ''}
                onSearch={handleSearch}
              />
            </Form.Item>

            <Form.Item name="seller_id" className="select-width">
              <Select
                dropdownMatchSelectWidth={false}
                suffixIcon={<img src={Images.arrowCDown} />}
                bordered={false}
                onChange={handleSelectSeller}
                className="language_fliter"
                placeholder="Select reseller"
                showSearch
                optionFilterProp="children"
              >
                <Select.Option value="">All</Select.Option>
                {sellers.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="ext_category_id" label="" className="select-width">
              <CategorySelectTree
                shopCategories={sellerCategories}
                onChange={handleSelectCategory}
                category={true}
                searchParsedSeller={searchParsedSeller}
                placeholder="Select category ext"
              />
            </Form.Item>

            <Form.Item name="in_category_id" label="" className="select-width">
              <CategorySelectTree
                shopCategories={shopCategories}
                onChange={handleSelectCategory}
                category={true}
                intCategory={intCategory}
                searchParsedSeller={searchParsedSeller}
                placeholder="Select category int"
              />
            </Form.Item>

            <Form.Item name="language" className="select-width">
              <Select
                dropdownMatchSelectWidth={false}
                suffixIcon={<img src={Images.arrowCDown} />}
                bordered={false}
                onChange={value => {
                  handleSetParams({ language: value });
                }}
                className="language_fliter"
                placeholder="Select language"
              >
                <Select.Option value="2fbb5fe2e29a4d70aa5854ce7ce3e20b">English</Select.Option>
                <Select.Option value="b80955ff390b464593237a45e9fa2d29">Albanian</Select.Option>
              </Select>
            </Form.Item>

            {/* {checkAccess(PermissionEnum.READ_USER) && (
              <Form.Item name="Assigne" label="" className="select-width">
                <Select
                  dropdownMatchSelectWidth={false}
                  suffixIcon={<img src={Images.arrowCDown} />}
                  bordered={false}
                  onChange={handleSelectUser}
                  optionFilterProp="children"
                  className="language_fliter"
                  placeholder="Select assigne"
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  <Select.Option value="">All</Select.Option>
                  <Select.Option value="unassigned">Unassigned</Select.Option>
                  {users.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {`${item.first_name} ${item.last_name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )} */}

            {!searchParsed?.status && (
              <Form.Item name="status" className="select-width">
                <Select
                  dropdownMatchSelectWidth={false}
                  suffixIcon={<img src={Images.arrowCDown} />}
                  bordered={false}
                  onChange={handleSelectStatus}
                  className="language_fliter"
                  placeholder="Select status"
                  showSearch
                  options={[
                    { value: 0, label: 'New' },
                    { value: 1, label: 'Pending' },
                    { value: 4, label: 'Approved' },
                    { value: ProductShopStatusEnum.IN_SHOP, label: 'In-Shop' },
                    { value: ProductShopStatusEnum.REMOVED, label: 'Removed' },
                  ]}
                />
              </Form.Item>
            )}

            <div
              className="pricing__clear-btn"
              onClick={clearFilter}
              style={{
                backgroundColor: activeFilters ? '#149914' : '',
                cursor: activeFilters ? 'pointer' : 'not-allowed',
                padding: activeFilters ? '5px 10px' : '',
              }}
            >
              <span style={{ color: activeFilters ? 'white' : 'gray' }}>Clear filter</span>
            </div>
          </div>
          <Space>
            {searchParsed?.status && statusSelect?.length !== 0 && (
              <Input.Group compact>
                <Select
                  placeholder="Select status"
                  value={productStatus}
                  onChange={value => setProductStatus(value)}
                >
                  {statusSelect.map(status => (
                    <Select.Option key={status.id} value={status.id}>
                      {status.name}
                    </Select.Option>
                  ))}
                </Select>
                <Button type="primary" onClick={handleChangeProductStatus}>
                  Change
                </Button>
              </Input.Group>
            )}

            {[ProductStatusEnum.APPROVED].includes(Number(searchParsed?.status)) && (
              <Popconfirm
                title={
                  <div>
                    <p className="title">{t('send_product_in_shop')}</p>
                    <div
                      style={{
                        marginBottom: '30px',
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexFlow: 'column',
                        paddingTop: '15px',
                      }}
                    >
                      <span style={{ marginBottom: '10px' }}>
                        <Checkbox
                          checked={exportType.sendRaport}
                          onChange={(e: CheckboxChangeEvent) => {
                            setExportType({
                              type: { configurator: false, main: true, image: true },
                              sendRaport: e?.target?.checked,
                              email: '',
                            });
                          }}
                        >
                          Send report with email
                        </Checkbox>
                      </span>
                      {exportType?.sendRaport && (
                        <Input
                          placeholder="Enter your email"
                          onChange={e => {
                            setExportType({ ...exportType, email: e?.target?.value });
                          }}
                        />
                      )}
                    </div>
                  </div>
                }
                okText="Yes"
                cancelText="No"
                onConfirm={handleApproveSendInShop}
              >
                <Button loading={loading} type="default">
                  Send to shop
                </Button>
              </Popconfirm>
            )}

            {[ProductShopStatusEnum.IN_SHOP, ProductShopStatusEnum.REMOVED].includes(
              searchParsed?.status as any,
            ) && (
              <Popconfirm
                title={
                  <div>
                    <p className="title">{t('send_product_in_shop')}</p>
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '15px',
                        alignItems: 'start',
                        flexFlow: 'column',
                      }}
                    >
                      <div>
                        <Checkbox
                          defaultChecked
                          disabled
                          onChange={(e: CheckboxChangeEvent) => {
                            setExportType({
                              ...exportType,
                              type: { ...exportType.type, main: e?.target?.checked },
                            });
                          }}
                        >
                          Main data
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          onChange={(e: CheckboxChangeEvent) => {
                            setExportType({
                              ...exportType,
                              type: { ...exportType.type, image: e?.target?.checked },
                            });
                          }}
                        >
                          Repair Images
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          onChange={(e: CheckboxChangeEvent) => {
                            setExportType({
                              ...exportType,
                              type: { ...exportType.type, configurator: e?.target?.checked },
                            });
                          }}
                        >
                          Repair Product configurators
                        </Checkbox>
                      </div>
                    </div>

                    <div
                      style={{
                        marginBottom: '30px',
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexFlow: 'column',
                        borderTop: '1px solid gray',
                        paddingTop: '15px',
                      }}
                    >
                      <span style={{ marginBottom: '10px' }}>
                        <Checkbox
                          checked={exportType.sendRaport}
                          onChange={(e: CheckboxChangeEvent) => {
                            setExportType({
                              ...exportType,
                              sendRaport: e?.target?.checked,
                              email: '',
                            });
                          }}
                        >
                          Send report with email
                        </Checkbox>
                      </span>
                      {exportType?.sendRaport && (
                        <Input
                          placeholder="Enter your email"
                          onChange={e => {
                            setExportType({ ...exportType, email: e?.target?.value });
                          }}
                        />
                      )}
                    </div>
                  </div>
                }
                okText="Yes"
                cancelText="No"
                onConfirm={handleSendProducsInShop}
              >
                <Button loading={loading} type="default">
                  Send to shop
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Space>
      </Form>
    </div>
  );
};
