import { Avatar, Col, Image, Row } from 'antd';
import { VariantImagesProp } from '../Product.types';
import { PictureOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { ProductImageModel } from '../../../../interfaces/models/Product.model';

export const VariantImages: FC<VariantImagesProp> = ({ images }) => {
  const sortedImages =
    images?.sort(
      (a: ProductImageModel, b: ProductImageModel) => (a.position || 0) - (b.position || 0),
    ) || [];

  return (
    <Row>
      <Col span={24}>
        <div className="images-wrapper">
          <div className="product-images full-height flex__images">
            {sortedImages?.length !== 0 ? (
              sortedImages?.map(({ id, url }: ProductImageModel) => {
                return (
                  <div className="is_cover-image" key={id}>
                    <Image src={url} key={id} alt={url?.split('/').pop()} />
                  </div>
                );
              })
            ) : (
              <>
                <Avatar
                  shape="square"
                  size={{ sm: 40, xxl: 100 }}
                  alt="No Variant Specific Photos"
                  icon={<PictureOutlined />}
                />
                <p style={{ alignSelf: 'center' }}>No Variant Specific Photos</p>
              </>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};
