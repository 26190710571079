import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { BusinessTypeEnum } from '../../../enums/BusinessType.enum';
import { useAuthStore } from '../../../store';
import { UpdateSellerModal } from '../LocalProductPage/UpdateSellerModal';
import api from '../../../api';
import { toastNotification } from '../../../utils/toastNotification';
import { ChangePassword } from './ChangePassword';

type EditLocalSellerProp = {
  visible: boolean;
  disabled: boolean;
  sellerById: any;
  setDisabled: (value: boolean) => void;
  setTableLoading: (value: boolean) => void;
  companyData?: any;
  sellerData?: any;
};

export const EditLocalSellersAuth = ({
  visible,
  sellerById,
  disabled,
  setDisabled,
  setTableLoading,
  companyData,
  sellerData,
}: EditLocalSellerProp) => {
  const [sellerId, setSellerId] = useState('');
  const [tags, setTags] = useState([]);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const [emailById, setEmailById] = useState('');
  const [registrationDate, setRegistrationDate] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageModal, setMessageModal] = useState(false);

  const { user } = useAuthStore();

  const handleClose = (value: { [key: string]: string | null }) => () => handleSetParams(value);

  const handleSetParams = (value: { [key: string]: string | null }) => {
    const params = queryString.parse(search);
    setDisabled(true);
    navigate({ search: queryString.stringify({ ...params, ...value }, { skipNull: true }) });
  };

  const closeMessageModal = () => setMessageModal(false);
  const openMessageModal = () => setMessageModal(true);

  const onFinishModal = (data: any) => {
    api('local-product/sendRequestEmail', 'POST', {
      data: {
        data: data.data,
        is_brand: false,
        registration_date: moment(data.registration_date?._d).format('YYYY-MM-DD'),
      },
    })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          form.resetFields();
          handleSetParams({ user_id: null, action: null });
          toastNotification('success', 'Success | Email was sent successfully');
          closeMessageModal();
        }
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const disabledDate = (current: any) => {
    // Can not select days after today
    return current && current.valueOf() > Date.now();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    handleSetParams({ user_id: null, action: null });
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(companyData);
    form.setFieldsValue(sellerData);
    setEmailById(sellerById.contact_email);
    setSellerId(sellerById.id);
    const brandsToArray = sellerById?.brands?.split(',');
    setTags(brandsToArray);
    setRegistrationDate(moment(companyData?.registration_date, 'YYYY-MM-DD'));
    return form.setFieldsValue(sellerById);
  }, [visible, sellerById.id]);

  return (
    <div>
      <Drawer
        open={searchParams.get('action') == 'edit-local-auth' && true}
        title={
          <div className="edit-wrapper edit-added-wrapper">
            <div className="edit-wrapper edit-added-x">
              <p onClick={handleClose({ user_id: null, action: null })}>X</p>
            </div>
            <Col>
              {searchParams.get('action') === 'edit-local-auth'
                ? 'Shiko sellerin lokal'
                : 'Rishikoni sellerin lokal'}
            </Col>
          </div>
        }
        width="600"
        className="local__drawer input__on"
        footer={
          <div className="user-action-button">
            {/* <Button
              className="seller-cancel-button"
              onClick={handleClose({ id: null, action: null })}
            >
              Anulo
            </Button> */}
            <Button
              className="seller-save-button"
              // form="save-seller-form"
              key="submit"
              htmlType="submit"
              onClick={openMessageModal}
            >
              Apliko per ndryshim
            </Button>
          </div>
        }
      >
        <Form
          id="save-seller-form"
          className="form-container edit-local-seller-auth"
          form={form}
          scrollToFirstError
          layout="vertical"

          // onFinish={handleSubmit}
        >
          <h2>INFORMACIONE RRETH BIZNESIT</h2>
          <Row gutter={16}>
            <Col span={24} className="status_username">
              <Form.Item
                label="Lloji i biznesit"
                name="type"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni emrin e biznesit',
                //   },
                // ]}
              >
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  placeholder="Ju lutem zgjedhni llojin e biznesit"
                  removeIcon={true}
                  // suffixIcon={<img src={Images.arrowCDown} />}
                  size="large"
                  disabled={true}
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {Object.values(BusinessTypeEnum).map(value => (
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="status_username">
              <Form.Item
                label="Emri i kompanisë"
                name="name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni emrin e kompanisë',
                //   },
                // ]}
              >
                <Input placeholder="Emri i kompanisë" disabled={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="status_username">
              <Form.Item
                label="Numri unik i biznesit"
                name="number"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni numrin unik të biznesit',
                //   },
                // ]}
              >
                <Input placeholder="Numri unik i biznesit" disabled={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12} className="seller-datepicker seller-auth-datepicker">
              <Form.Item
                name="registration_date"
                label="Data e regjistrimit të kompanisë"
                rules={[
                  {
                    required: true,
                    message: 'Ju lutem vendosni daten e regjistrimit te kompanisë',
                    // pattern: /\d{2}\/\d{2}\/\d{4}/,
                  },
                ]}
              >
                <Space direction="vertical">
                  <DatePicker
                    defaultValue={registrationDate}
                    format="YYYY-MM-DD"
                    size="large"
                    placeholder="Zgjedh daten"
                    style={{ height: '40px' }}
                    disabled={true}
                    disabledDate={disabledDate}
                  />
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sku_prefix"
                label="Prefix"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni sku',
                //   },
                //   {
                //     min: 3,
                //     message: 'Sku duhet te kete se paku 3 karaktere',
                //   },
                // ]}
              >
                <Input
                  type="text"
                  placeholder="Prefix"
                  maxLength={3}
                  disabled={disabled || sellerById?.sku_prefix}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="status_username">
              <Form.Item
                label="Numri i telefonit"
                name="contact_phone"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni numrin tuaj te telefonit',
                //   },
                // ]}
              >
                <Input placeholder="Numri i telefonit" type="text" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="status_username">
              <Form.Item
                label="Emaili"
                name="contact_email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni emailin',
                //   },
                // ]}
              >
                <Input placeholder="Emaili" type="email" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} className="status_username">
              <Form.Item
                label="Emri"
                name="contact_firstname"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni emrin',
                //   },
                // ]}
              >
                <Input placeholder="Emri" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12} className="status_username">
              <Form.Item
                label="Mbiemri"
                name="contact_lastname"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni mbiemrin',
                //   },
                // ]}
              >
                <Input placeholder="Mbiemri" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <div className="change__password-btn">
              <p>Password</p>
              <Button onClick={showModal}>Change password</Button>
            </div>
          </Row>
          <Row gutter={16}>
            <Col span={24} className="status_username">
              <Form.Item
                label="Adresa"
                name="address"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Ju lutem vendosni adresen',
                //   },
                // ]}
              >
                <Input placeholder="Address" disabled={true} />
              </Form.Item>
            </Col>
          </Row>

          {/* <Row className="date">
            <Col span={12} className="status_username">
              <Form.Item
                label="Company registration date*"
                name="registration_date"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input type="date" placeholder="2022-06-01" />
              </Form.Item>
            </Col>
          </Row> */}
          <Row>
            <h2>ADRESA E BIZNESIT</h2>
            <Col span={24} className="status_username">
              <Form.Item
                label="Shteti"
                name="country"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  size="large"
                  optionFilterProp="children"
                  // suffixIcon={<img src={Images.arrowCDown} />}
                  placeholder="Zgjedh shtetin"
                  defaultValue="Kosove"
                  disabled={true}
                  removeIcon={true}
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  <Select.Option value="Kosove">Kosove</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={18} className="status_username">
              <Form.Item
                label="Komuna"
                name="city"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input type="text" placeholder="Prishtine" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={6} className="status_username">
              <Form.Item
                label="Kodi postar"
                name="zip_code"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input type="text" placeholder="10000" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
      <ChangePassword isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      <UpdateSellerModal
        closeModal={closeMessageModal}
        modal={messageModal}
        onFinishModal={onFinishModal}
      />
    </div>
  );
};
