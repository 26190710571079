import { Layout, Select } from 'antd';
import queryString from 'query-string';
// store
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import { EditLocalSellersAuth } from '../../pages/Dashboard/LocalSellersPage/EditLocalSellerAuth';
import { useAuthStore } from '../../store';
import { removeAuthToken } from '../../utils/authToken';
import { toastNotification } from '../../utils/toastNotification';
import * as Icon from 'react-feather';
import { LogoutIcon } from './icons';
import { usePersistedShopId } from '../../hooks/usePersistedShopId';

export const Header = () => {
  const { isAuthenticated, removeAuth, user } = useAuthStore();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [sellerById, setSellerById] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [sellerData, setSellerData] = useState([]);
  const [shops, setShops] = useState<any>([]);
  const { shopId, setPersistedShopId } = usePersistedShopId();

  const { search } = useLocation();

  const handleLogout = () => {
    removeAuthToken();
    removeAuth();
  };

  const handleSetParams = (user_id: any, action: 'edit-local-auth' | null) => () => {
    navigate(
      {
        search: queryString.stringify(
          { ...searchParsed, user_id, action, shopId },
          { skipNull: true },
        ),
      },
      { replace: true },
    );
  };

  const searchParsed = useMemo(() => queryString.parse(search), [search]);

  const getShops = async () => {
    api('shops', 'GET', null)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setShops(result);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const getUserById = async () => {
    api('local-seller/getSellerByUserId/' + user?.id, 'GET', null)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setCompanyData(result?.company);
          setSellerData(result?.seller);
          showEditLocalSeller();
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const handleSetError = (msg: string) => {
    // toastNotification('error', msg);
    setLoading(false);
  };

  const showEditLocalSeller = () => setVisible(true);

  const handleShopChange = (shopId: string) => {
    setPersistedShopId(shopId);
    window.location.reload();
  };

  useEffect(() => {
    getUserById();
    getShops();
  }, []);

  return (
    <div className="header__wrapper-sticky">
      <EditLocalSellersAuth
        visible={visible}
        disabled={disabled}
        setDisabled={setDisabled}
        sellerById={sellerById}
        setTableLoading={setLoading}
        companyData={companyData}
        sellerData={sellerData}
      />
      <Layout.Header className="site-layout-background header-layout">
        {isAuthenticated && (
          <>
            <div className="user_header" onClick={handleSetParams(user?.id, 'edit-local-auth')}>
              <span className="image__user-header">
                <Icon.User color="#ffffff" size={20} className="permissions-icon-tick" />
              </span>
              <div className="welcome__user">
                <p>Welcome</p>
                <span>{user?.fullName}</span>
              </div>
            </div>
            {user?.roles.includes('Admin') ||
              (user?.roles.includes('Super Admin') && (
                <Select
                  value={shopId}
                  onChange={handleShopChange}
                  placeholder="Select Shop"
                  style={{ width: 200, marginLeft: 'auto', marginRight: 20 }}
                >
                  {shops.map((shop: any) => (
                    <Select.Option key={shop.id}>{shop.name}</Select.Option>
                  ))}
                </Select>
              ))}
            <span onClick={handleLogout}>
              <LogoutIcon />
              Logout
            </span>
          </>
        )}
      </Layout.Header>
    </div>
  );
};
