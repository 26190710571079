import { ProductShopStatusEnum, ProductStatusEnum } from '../enums/ProductStatus.enum';

export const checkStatusAllProduct = (status: number | string, productShopStatus?: string) => {
  const baseStatuses = [
    { id: 0, name: 'New' },
    { id: 1, name: 'Pending' },
    { id: 3, name: 'Ignored' },
    { id: 4, name: 'Approved' },
  ];

  const shopStatuses = [
    { id: ProductShopStatusEnum.IN_SHOP, name: 'In-Shop' },
    { id: ProductShopStatusEnum.REMOVED, name: 'Removed-from-shop' },
    { id: ProductShopStatusEnum.ARCHIVED, name: 'Archived' },
  ];

  const baseStatus = baseStatuses.find(({ id }) => status === id)?.name;

  if (status === 4 && productShopStatus) {
    const shopStatus = shopStatuses.find(({ id }) => productShopStatus === id);
    return shopStatus ? shopStatus.name : baseStatus;
  }

  return baseStatus;
};

export const changeProductStatus = (status: number | string) => {
  const { NEW, PENDING, APPROVED, IGNORED } = ProductStatusEnum;
  const { IN_SHOP, REMOVED, ARCHIVED } = ProductShopStatusEnum;
  const list = [
    { id: NEW, name: 'New', allowed: [PENDING, IGNORED] },
    {
      id: IGNORED,
      name: 'Ignore',
      allowed: [NEW, PENDING, ARCHIVED],
    },
    { id: PENDING, name: 'Pending', allowed: [APPROVED, IGNORED] },
    {
      id: APPROVED,
      name: 'Approve',
      allowed: [PENDING, IGNORED],
    },
    {
      id: IN_SHOP,
      name: 'In Shop',
      allowed: [REMOVED],
    },
    {
      id: REMOVED,
      name: 'Remove',
      allowed: [ARCHIVED],
    },
    { id: ARCHIVED, name: 'Archive', allowed: [APPROVED, NEW, IGNORED] },
  ];

  const findStatus = list.find(({ id }) => String(id) === String(status));

  return list.filter(({ id }) => findStatus?.allowed.includes(id as never));
};

export const changeLocalProductStatus = (status: number | string) => {
  const { NEW, IGNORED, APPROVED } = ProductStatusEnum;
  const { IN_SHOP, ARCHIVED } = ProductShopStatusEnum;

  const list = [
    { id: NEW, name: 'New', allowed: [APPROVED, ARCHIVED] },
    { id: IGNORED, name: 'Ignore', allowed: [NEW, ARCHIVED] },
    { id: APPROVED, name: 'Approve', allowed: [IGNORED, ARCHIVED] },
    { id: IN_SHOP, name: 'In Shop', allowed: [ARCHIVED] },
    { id: ARCHIVED, name: 'Archive', allowed: [IN_SHOP] },
  ];
  const findStatus = list.find(({ id }) => String(id) === String(status));
  return list.filter(({ id }) => findStatus?.allowed?.includes(id as never));
};

export const changeLocalProductShopStatus = (status: number | string) => {
  const { NEW, IGNORED, APPROVED } = ProductStatusEnum;
  const { IN_SHOP, ARCHIVED } = ProductShopStatusEnum;
  const list = [
    { id: NEW, name: 'New', allowed: [NEW] },
    { id: IGNORED, name: 'Ignore', allowed: [IGNORED] },
    { id: APPROVED, name: 'Approve', allowed: [APPROVED] },
    { id: IN_SHOP, name: 'In Shop', allowed: [ProductShopStatusEnum.IN_SHOP] },
    { id: ARCHIVED, name: 'Archive', allowed: [ProductShopStatusEnum.ARCHIVED] },
  ];
  const findStatus = list.find(({ id }) => String(id) === String(status));
  return list.filter(({ id }) => findStatus?.allowed?.includes(id as never));
};

export const checkOrderStatus = (status: string) =>
  [
    'open',
    'accepted',
    'canceled',
    'canceled_by_vendor',
    'confirmed',
    'po_sent',
    'ready_for_pickup',
    'ready_for_delivery',
    'en_route_to_warehouse',
    'customs_clearance',
    'arrived_in_warehouse',
    'prepared_for_courier',
    'out_for_delivery',
    'retry_delivery',
    'failed_delivery',
    'delivered_paid',
    'delivered',
    'refused',
    'returing_to_warehouse',
    'returned',
  ].find(i => status.toLowerCase().replace(/ /g, '_') === i);

export const checkActiveStatus = (status: number) =>
  [
    { id: 0, name: 'Active' },
    { id: 1, name: 'Deactive' },
  ].find(({ id }) => status === id)?.name;

export const checkColorStatus = (status: number) =>
  [
    { id: 0, name: 'pending' },
    { id: 1, name: 'cancel' },
    { id: 2, name: 'in-progress' },
    { id: 3, name: 'Delivered-by-reseller' },
    { id: 4, name: 'Arrived' },
    { id: 5, name: 'Delivered' },
    { id: 6, name: 'Completed' },
    { id: 7, name: 'Returned' },
    { id: 8, name: 'Failed-delivery' },
    { id: 9, name: 'Failed-to-send' },
  ].find(({ id }) => status === id)?.name;
